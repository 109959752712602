<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">
          <router-link to="/profile/calendar" class="text">
            <i class="dropdown-icon si si-arrow-left"></i>
          </router-link>
          Otomatik Takvim Oluşturma
        </h3>

        
      </div>
      <div class="card-body">
        <div
          class="form-check form-switch"
          style="position: absolute; right: 25px"
        >
          <label class="form-check-label" for="dayStatus"
            >Gün Pasif/Aktif</label
          >
          <input
            class="form-check-input"
            type="checkbox"
            id="dayStatus"
            @change="dayStatus"
            :checked="day_Status"
          />
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <!--<div class="text-center">
              <h3>{{ selectedDate }}</h3>
            </div>
            <br />-->
            <div v-if="false" class="form-group">
              <label class="form-label">Hazır Planlar</label>
              <select
                class="form-select"
                name="templateSelected"
                @change="selectedTemplated"
                aria-label="Default select example"
              >
                <option selected>Şablon Seçin</option>
                <option
                  v-for="(item, i) in calendarTemplate"
                  :key="'option' + i"
                  :value="JSON.stringify(item)"
                >
                  {{ item.template_name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <h4 style="font-weight: bolder">Çalışma Saatleri</h4>
        <div class="row">
          <div class="col-sm-6 col-md-6">
            <div class="form-group">
              <label class="form-label">Başlangıç</label>
              <input
                type="time"
                name="begin"
                v-model="begin"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="form-group">
              <label class="form-label">Bitiş</label>
              <input
                type="time"
                name="end"
                v-model="end"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="form-group">
              <label class="form-label">Seans Süresi (dk)</label>
              <input
                type="number"
                name="sessionDuration"
                v-model="sessionDuration"
                class="form-control"
                placeholder="Seans Süresini Dakika Cinsinden Girin"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="form-group">
              <label class="form-label">Seans Arası Süre (dk)</label>
              <input
                type="number"
                v-model="sessionBetweenDuration"
                name="sessionBetweenDuration"
                class="form-control"
                placeholder="Seans Arası Süresini Dakika Cinsinden Girin"
              />
            </div>
          </div>
        </div>
        <div class="d-grid gap-2">
          <button type="button" @click="hourCreator" class="btn btn-primary">
            Takvimi Oluştur
          </button>
        </div>
        <hr v-if="hours.length > 0" />

        <div class="card-body" v-if="hours.length > 0 && hours != null">
          <div
            v-for="(item, i) in hours"
            :key="i"
            class="col-12 form-switch"
            style="border-bottom: 1px solid rgb(187 116 148)"
          >
            <div class="col-sm-1 col-md-1 col-xl-1">
              <input
                style="margin: 0 auto"
                class="form-check-input"
                type="checkbox"
                :disabled="item.disabled"
                @change="hourStatus(i)"
                :id="'check' + i"
                :checked="boolCon(item.available)"
              />
            </div>
            <div class="col-sm-10 col-md-10 col-xl-10">
              <label class="row form-check-label" :for="'check' + i">
                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <label class="form-label">Başlangıç</label>
                    <input
                      type="time"
                      name="begin"
                      :disabled="item.disabled"
                      :id="'begin' + i"
                      v-model="item.begin"
                      class="form-control"
                      @change="control('begin' + i)"
                      :min="i > 0 ? hours[i - 1].end : 0"
                    />
                  </div>
                </div>
                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <label class="form-label">Bitiş</label>
                    <input
                      type="time"
                      name="end"
                      :disabled="item.disabled"
                      :id="'end' + i"
                      v-model="item.end"
                      @change="control('end' + i)"
                      class="form-control"
                      :min="item.begin"
                    />
                  </div>
                </div>
              </label>
            </div>
            <div class="col-sm-2 col-md-2 col-xl-2">
              <button
                @click="removeItem(i)"
                type="button"
                class="btn btn-primary"
                :disabled="item.disabled"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <button
          type="button"
          @click="addNewTime"
          class="btn btn-primary w-100"
          v-if="hours.length > 0"
        >
          Saat Ekle
        </button>
        <small v-if="hours.length > 0"
          >* İlgili günlere oluşturulmuş randevular varsa yapılan değişiklikler
          kaydedilemez!</small
        >
        <div class="row" v-if="false">
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label class="form-label">Şablon Başlığı</label>
              <input
                type="text"
                name="title"
                class="form-control"
                value=""
                placeholder="Şablon başlığı girin"
              />
            </div>
          </div>
          <small
            >* Yaptığınız işlemleri daha sonrada kullanmak için şablon
            oluşturabilirsiniz. Bunun için bir başlık girin ve şablonu kaydet
            butonunu kullanın.</small
          >
          <div class="d-grid gap-2">
            <button type="button" @click="templateSave" class="btn btn-primary">
              Şablonu Kaydet
            </button>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="col-sm-12 col-md-12">
          <div class="form-group">
            <label class="form-label">Bilgileri çoklu kaydet</label>
            
            <FunctionalCalendar
              v-model="calendarData"
              :configs="calendarConfigs"
            ></FunctionalCalendar>

          </div>
        </div>
        <button
          type="button"
          @click="save"
          id="saveButton"
          class="btn btn-primary"
        >
          Randevu Bilgilerini Kaydet
        </button>
      </div>
    </div>
  </div>
</template>
<script>

import FunctionalCalendar from "vue-functional-calendar/src/components/FunctionalCalendar.vue";
export default {
    name: "CalendarUpdate",
    created() {
      $("html, body").animate(
        {
          scrollTop: $("#content-app").offset().top - 60,
        },
        0
      );
        document.title = "Randevu Takvimi";
        this.selectedDate =
            this.$route.params.day +
                "-" +
                this.$route.params.month +
                "-" +
                this.$route.params.year;
        this.$store
            .dispatch("calendarGet", { date: this.selectedDate })
            .then((value) => {
            this.dateInfo = value[0];
            if (this.dateInfo.available != null) {
                this.day_Status = this.dateInfo.available;
            }
            else {
                this.day_Status = 1;
            }
            value[0].hourList.forEach((element) => {
                let el = JSON.parse(element.hour);
                el.id = element.id;
                el.available = element.available;
                el.disabled = element.disabled;
                this.hours.push(el);
            });
        });
        this.$store.dispatch("calendarTemplateGet").then((value) => {
            this.calendarTemplate = value;
        });
    },
    data() {
        return {
            selectedDate: "",
            dateInfo: [],
            hours: [],
            calendarTemplate: [],
            day_Status: 1,
            selectedTemplate: [],
            begin: "",
            planner: false,
            end: "",
            sessionDuration: "",
            sessionBetweenDuration: "",
            calendarData: {},
            calendarConfigs: {
                sundayStart: false,
                dateFormat: "yyyy-mm-dd",
                isDatePicker: false,
                isDateRange: false,
                isMultipleDatePicker:true,
                disabledDates: ['beforeToday'],
                dayNames:['Pzt', 'Sl', 'Çar', 'Per', 'Cm', 'Cmt', 'Pz'],
                monthNames:['Ocak','Şubat','Mart','Nisan','Mayıs','Haziran','Temmuz','Ağustos','Eylül','Ekim','Kasım','Aralık']
            },
        };
    },
    methods: {
        autoPlanner() {
            if (this.planner == false) {
                this.planner = true;
            }
            else {
                this.planner = false;
            }
        },
        selectedTemplated() {
            let templateSelected = document.querySelector("select[name=templateSelected]").value;
            let space = JSON.parse(templateSelected);
            this.selectedTemplate = space;
            document.querySelector("input[name=title]").value = space.template_name;
            this.begin = JSON.parse(space.content).begin;
            this.end = JSON.parse(space.content).end;
            this.sessionBetweenDuration = JSON.parse(space.content).sessionBetweenDuration;
            this.sessionDuration = JSON.parse(space.content).sessionDuration;
            this.hours = JSON.parse(space.content).hours;
        },
        templateSave() {
            let title = document.querySelector("input[name=title]").value;
            if (title == null || title == "") {
                this.$vToastify.warning("Template başlığı girilmedi", "Uyarı!");
            }
            else if (this.begin == null || this.begin == "") {
                this.$vToastify.warning("Başlangıç saati girilmedi", "Uyarı!");
            }
            else if (this.end == null || this.end == "") {
                this.$vToastify.warning("Bitiş saati girilmedi", "Uyarı!");
            }
            else if (this.sessionDuration == null || this.sessionDuration == "") {
                this.$vToastify.warning("Seans süresi girilmedi", "Uyarı!");
            }
            else if (this.sessionBetweenDuration == null ||
                this.sessionBetweenDuration == "") {
                this.$vToastify.warning("Seanslar arası süre girilmedi", "Uyarı!");
            }
            else {
                let list = [
                    {
                        id: 0,
                        content: {
                            begin: this.begin,
                            end: this.end,
                            sessionDuration: this.sessionDuration,
                            sessionBetweenDuration: this.sessionBetweenDuration,
                            hours: this.hours,
                        },
                    },
                ];
                if (this.selectedTemplate != null) {
                    list.id = this.selectedTemplate.id;
                }
                this.$store
                    .dispatch("calendarTemplateSave", {
                    title: title,
                    selectedTemplate: JSON.stringify(list),
                })
                    .then((value) => {
                    this.$vToastify.success("Template Kaydedildi", "Başarılı!");
                });
            }
        },
        boolCon(item) {
            return item == 1 ? true : false;
        },
        hourStatus(i) {
            this.hours[i].available = document.getElementById("check" + i).checked
                ? 1
                : 0;
        },
        dayStatus() {
            this.day_Status = document.getElementById("dayStatus").checked ? 1 : 0;
        },
        save() {
          if(this.hours.length==0){
            this.hourCreator();
          }
            document.getElementById("saveButton").disabled = true;
            document.getElementById("saveButton").innerHTML =
                "Bilgiler kayıt ediliyor..";
            let selectedDate = this.$route.params.year +
                "-" +
                this.$route.params.month +
                "-" +
                this.$route.params.day;
            //let day = document.querySelector("select[name=day]").value;
            let phoneSave = {
                available: this.day_Status,
                hours: JSON.stringify(this.hours),
                date: selectedDate,
                dates:JSON.stringify(this.calendarData.selectedDates),
                day: 0,
            };
            this.$store.dispatch("calendarUpdate", phoneSave).then((value) => {
                this.$vToastify.success("Randevu bilgileri kaydedildi", "Başarılı!");
                document.getElementById("saveButton").disabled = false;
                document.getElementById("saveButton").innerHTML =
                    "Randevu Bilgilerini Kaydet";
            });
        },
        addNewTime() {
            this.hours.push({ begin: "", end: "", available: 1 });
        },
        removeItem(index) {
            this.hours.splice(index, 1);
        },
        control(id) {
            let selectedDate = this.$route.params.year +
                "-" +
                this.$route.params.month +
                "-" +
                this.$route.params.day;
            let minVal = document.getElementById(id).min;
            let value = document.getElementById(id).value;
            var arr = (selectedDate + " " + minVal + ":00").split(/[- :]/);
            let timeMinVal = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
            var arr = (selectedDate + " " + value + ":00").split(/[- :]/);
            let timeValue = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
            if (timeValue < timeMinVal) {
                document.getElementById(id).value = minVal;
            }
        },
        hourCreator() {
            this.hours = [];
            let begin = document.querySelector("input[name=begin]").value;
            let end = document.querySelector("input[name=end]").value;
            let sessionDuration = document.querySelector("input[name=sessionDuration]").value;
            let sessionBetweenDuration = document.querySelector("input[name=sessionBetweenDuration]").value;
            let selectedDate = this.$route.params.year +
                "-" +
                this.$route.params.month +
                "-" +
                this.$route.params.day;
            var arr = (selectedDate + " " + end + ":00").split(/[- :]/);
            let endDate = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
            var arr = (selectedDate + " " + begin + ":00").split(/[- :]/);
            let beginDate = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
            let beetween = endDate - beginDate;
            if (beetween > 0) {
                let beetweenMinutes = beetween / 1000 / 60;
                let totalDuration = parseFloat(sessionDuration) + parseFloat(sessionBetweenDuration);
                let loopSize = Math.floor(parseFloat(beetweenMinutes) / parseFloat(totalDuration));
                var arr = (selectedDate + " " + begin + ":00").split(/[- :]/);
                let start = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
                for (let index = 0; index < loopSize; index++) {
                    let beginHour = this.formatTime(start.getHours()) +
                        ":" +
                        this.formatTime(start.getMinutes());
                    start.setMinutes(start.getMinutes() + parseFloat(sessionDuration));
                    let endHour = this.formatTime(start.getHours()) +
                        ":" +
                        this.formatTime(start.getMinutes());
                    this.hours.push({ begin: beginHour, end: endHour, available: 1 });
                    start.setMinutes(start.getMinutes() + parseFloat(sessionBetweenDuration));
                }
            }
        },
        formatTime(myNumber) {
            return ("0" + myNumber).slice(-2);
        },
    },
    mounted() { },
    components: { FunctionalCalendar }
};
</script>